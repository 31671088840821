import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import Head from '../components/head'
import Layout from '../components/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import hosGeldin from "../images/invitation.png"
import iosButton from "../images/iosbutton.png"
import androidButton from "../images/androidbutton.png"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const InvitationPage = () => {
const appLink = "cember://invitation/" 
const [invitationCode, setCode] = useState(false);

useEffect(() => {
    const params = new URL(window.location).searchParams;
    const code = params.get('code');
    setCode(code);
}, []);

return (
<Layout>
<Head title="Hadi Çember'e Katıl" description="Altın günü modeli ile para biriktirme uygulaması Çember'e davet edildin. Hadi hemen gel, seni bekliyorlar." />
<div className="row invitation">
    <div className="hero-container">
    <div className="container">
    <div className="col-6">

        <h1>Çember'e Hoş Geldin</h1>    
        <p> Arkadaşlarınla altın günü modeli ile para biriktirmeni sağlayan Çember'e davet edildin. 
        </p>
           
        <p>Davet Kodun:</p>
        <h3>{invitationCode}</h3> 
         <p>
            Uygulaman varsa <b>Çemberlerim</b> ekranından aşağıdaki kodu girerek davet edildiğin çemberi görebilir 
            veya aşağıdaki davet linkine tıklayabilirsin.
            Hadi hemen gel, arkadaşların seni bekliyor. 
        </p>       
        <p>
            <a className="openapp" href={`${appLink}${invitationCode}`}  rel="noreferrer" >Çember'i daha önce indirdiysen buraya tıklayabilirsin.</a>
        </p>
        
        
        <p>Uygulamayı indirmediysen aşağıdaki linkten uygulamayı indirebilirsin:</p>
   

        <div className="downloadbuttons">
            <a onClick={e => {
                // e.preventDefault()
                trackCustomEvent({
                category: "ios",
                action: "click",
                label: "invitation"
                })
            }}  href="https://apps.apple.com/us/app/id1532621504" rel="noreferrer"  target="_blank"><img src={iosButton} alt="IOS indir"/></a>
            <a onClick={e => {
                //  e.preventDefault()
                trackCustomEvent({
                category: "android",
                action: "click",
                label: "invitation"
                })
            }}  href="https://play.google.com/store/apps/details?id=ist.tio.cember" rel="noreferrer"  target="_blank"><img src={androidButton} alt="Android indir"/></a>
        </div>
        
        <p className="detail-link">
            Daha detaylı bilgi için   
            <Link to="/how"> <b>nasıl çalışır</b></Link> sayfamıza göz at! 
        </p>
        
    </div>
    <div className="col-6">
    <img src={hosGeldin} alt="Hoş Geldin" />

    </div>
    </div>
</div>
</div>
    </Layout>

    
)
    
    
}

export default InvitationPage